@media only screen and (max-width: 915px) {
  body {
    h1 {
      font-size: 7.5vw;
    }
    h2 {
      font-size: 3.8vw;
    }
    h3 {
      font-size: 3.5vw;
    }
    p {
      font-size: 3.5vw;
      margin-left: auto;
      margin-right: auto;
    }
    .red {
      font-size: 3vw;
    }
  }
  .buttons {
    margin-left: 4vw;
    margin-right: 4vw;
  }
}

body {
  overflow: hidden;
  border: 0;
  padding: 0;
  margin: 0;
  height: 100vh;
  width: 100%;
  text-align: center;
  background-color: rgb(0, 0, 0);
  color: whitesmoke;
}
* {
  margin: 0;
  border: 0;
  padding: 0;
  font-family: "Arial", serif;
}

.content {
  /* background-color: red; */
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.logo {
  filter: drop-shadow(1px 1px 5px rgb(0, 0, 0));
}

h1 {
  font-size: 70px;
  font-weight: 900;
  margin: 10px;
  text-shadow: 1px 1px 5px rgb(0, 0, 0);
}
h2 {
  font-size: 35px;
  font-weight: 700;
  margin: 10px;
  text-shadow: 1px 1px 5px rgb(0, 0, 0);
  font-style: italic;
  margin-bottom: 80px;
}
h3 {
  font-size: 25px;
  font-weight: 300;
  margin: 10px;
  text-shadow: 1px 1px 5px rgb(0, 0, 0);
}
.red {
  font-size: 20px;
  color: black;
  margin: 5px;
  text-shadow: none;
}
.red:hover {
  cursor: pointer;
}

.banner {
  background-color: red;
}
p {
  /* background-color: red; */
  font-size: 25px;
  font-weight: 300;
  margin: 50px;
  text-shadow: 1px 1px 5px rgb(0, 0, 0);
}
img {
  margin: 20px;
  height: 100px;
  width: 100px;
}
video {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  opacity: 50%;
}

.footerTxt {
  color: rgb(210, 210, 210);
  font-size: 10px;
  margin-top: 70px;

  margin-bottom: 30px;
}
.footerTxt:hover {
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.button1 {
  color: whitesmoke;
  font-weight: 800;
  text-decoration: underline;
}
.button1:hover {
  cursor: pointer;
  scale: 105%;
  transition: 300ms;
  color: rgb(255, 189, 189);
}

.contractLine:hover {
  cursor: pointer;
}
.copyIcon {
  /* filter: hue-rotate(90deg); */
  filter: drop-shadow(1px 1px 5px rgb(0, 0, 0));
  color: white;
}

.SnackbarProviderButton {
  background-color: transparent;
  border: none;
  color: whitesmoke;
}
.SnackbarProviderButton:hover {
  cursor: pointer;
}
